import Spinner from 'components/Spinner'

import { Container, Button, Menu, MenuItem } from "./styled"

export default function Dropdown({ isLoading, text, items = [], btSize,...rest }) {
    return (
        <Container {...rest}>
            <Button style={{ fontSize: btSize }} >{isLoading ? <Spinner /> : text}</Button>

            <Menu>
                {items.map((item, index) => (
                    <MenuItem key={index} onClick={item.onClick} href='#'>{item.text}</MenuItem>
                ))}
            </Menu>
        </Container>
    )
}

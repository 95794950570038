import React from 'react';
import { CardLink } from './style';
import 'tippy.js/dist/tippy.css';

const Component = ({ name, fileUrl, linkTo }) => {

  return (
    <CardLink to={linkTo}>
      <img src={fileUrl ? `${process.env.REACT_APP_URL_API}/${fileUrl}` : '/standardCard.png'} alt="analyticdi" />
      <p style={{ textAlign: 'center' }} data-content={name}>{name}</p>
    </CardLink>
  );
};

export default Component;
import { useContext, useEffect, useState } from 'react';
import { Login, Image, Form, Logo, LoginBox, ImgDatoHub, ContainerFormLogin } from './styled'
import { Context } from 'contexts/context';
import BaseComponent from 'components/ComponentBase';

const Base = ChildrenComponent => {

  const ComponentBase = props => {
    const [srcImg, setSrcImg] = useState("")
    const [marginBot, setMarginBot] = useState(0)

    const { firm } = useContext(Context)

    const link = document.getElementById('dynamic-favicon');

    useEffect(() => {
      if (!firm) return; // Wait for firm to be defined

      // if (link && link.rel === 'icon') {
      //   link.href = `/logos/favicon-${firm.firm_name}.ico`
      // }

      function handleResize() {
        // FIRM TO CHANGE
        if (window.innerWidth <= 960 && (firm.id === 11 || !firm.file_logo)) {
            setSrcImg(`/logoDatoHub.jpg`)
            setMarginBot(50)
          } else {
            if (firm.file_logo) {
              setSrcImg(`${process.env.REACT_APP_URL_API}/${firm.file_logo}`)
            } else {
              setSrcImg(`/logo-nivel-3-2-dato.png`)
            }
            setMarginBot(0)
          }
      }

      window.addEventListener('resize', handleResize);
      handleResize()

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [firm]);

    if (!firm) return null; // Render nothing or a loading state until firm is available

    return (
      <BaseComponent>
        <Login backgroundImage={firm.file_background ? `${process.env.REACT_APP_URL_API}/${firm.file_background}` : "/edificio-dato.jpg"} >
        {/* TO BE FIXED - background mobile backgrounImageCel={"/fundo-celular.png"}> */}

          <Form>
            <LoginBox>
              <Logo marginBottom={marginBot}>
                {srcImg && <img id="logoImage" src={srcImg} alt="analitycdbi" />}
              </Logo>
              <ChildrenComponent {...props} />
            </LoginBox>
          </Form>
          <Image>
            <div></div>
          </Image>
        </Login>
      </BaseComponent>
    )
  }

  return ComponentBase
}

export default Base
import { BtnBox } from './styled'

const Component = props => {
    return (
        <BtnBox>
            {props.children}
        </BtnBox>
    )
}

export default Component
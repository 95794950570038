import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import Table from 'components/Table'
import Select from 'components/Form/LabelSelect'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import Modal from 'components/Modal'
import BtnRed from 'components/Button/BtnRed'
import BtnBox from 'components/Button/BtnBox'
import BtnWhite from 'components/Button/BtnWhite'
import BtnOrange from 'components/Button/BtnOrange'

import api from 'services/api/api'

import { setErrors } from 'utils'

const UpdateCarrouselReport = () => {
    const [visibleModal, setVisibleModal] = useState(false)

    const [carousel, setCarousel] = useState({})
    const [carouselReport, setCarouselReport] = useState({})

    const [messages, setMessages] = useState({ messages: [], alert: '' })

    const history = useHistory()
    const params = useParams()

    const formik = useFormik({
      initialValues: { 
        time: `Minutos`,
        duration: (carouselReport.time / 60000).toFixed(2),
        report_section: carouselReport.report_section,
        roles: carouselReport.roles,
      },
      enableReinitialize: true,
      onSubmit: async () => {
        const { duration, time, report_section, roles } = formik.values

        try {
          await api.put(`/carousels/${params.carouselId}/reports/${params.id}`, {
            time: time === "Segundos" ? duration * 1000 : duration * 1000 * 60,
            report_section,
            roles
          })

          history.push(`/carrosseis/${params.carouselId}/relatorios`)
        } catch (error) {
          setMessages({ messages: setErrors(error), alert: 'alert-orange' })
        }
      }
    })

    useEffect(() => {
      (async () => {
        try {
          const { data } = await api.get(`/carousels/${params.carouselId}`)
          setCarousel(data)
        } catch(error) {}
      })()
    }, [params.carouselId])

    useEffect(() => {
      (async () => {
        try {
          const { data } = await api.get(`/carousels/${params.carouselId}/reports/${params.id}`)
          setCarouselReport(data)
        } catch(error) {}
      })()
    }, [params.carouselId, params.id])

    const handleDelete = async () => {
      try {
        await api.delete(`/carousels/${params.carouselId}/reports/${params.id}`)

        return history.push(`/carrosseis/${params.carouselId}/relatorios`);
      } catch(error) {
        setMessages({ messages: setErrors(error), alert: 'alert-orange' })
      }
    }

    return (
        <>
        <TitleBar label="Painel TV" currentPage="" />

        <Content>
          <Card>
            <CardTitle title='Carrousel'></CardTitle>

            <Table>
                    <thead>
                    <tr>
                        <th>Nome</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{carousel.name}</td>
                    </tr>
                    </tbody>
                </Table>
            </Card>

          <Card>
            <CardTitle title="Editar">
              <BtnOrange onClick={() => setVisibleModal(true)}>Excluir</BtnOrange>
            </CardTitle>

            <Content>
              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Messages formMessages={messages.messages} alert={messages.alert} />

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '20%' }}>
                      <Input name="duration" type="number" label="Duração" formik={formik} />
                    </div>
                    <div style={{ width: '78%' }}>
                      <Input 
                        name="time" label="Tempo" formik={formik}
                        readOnly={true} placeholder="Minutos">
                      </Input>
                      {/* <Select  name="time" label="Tempo" formik={formik}>
                        <option value="">Selecione...</option>

                        {['Segundos', 'Minutos'].map(item => (
                          <option selected={(carouselReport.time / 1000 >= 60 ? `Minutos` : `Segundos`) === item} key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Select> */}
                    </div>


                    
                  </div>
                  <Input name="report_section" label="Report Section" formik={formik} />
                  <Input name="roles" label="Roles" formik={formik} />
                  
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
                </form>
              </CardBody>
            </Content>
          </Card>
        </Content>

        <Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
          <Card>
            <Content>
              <Content>
                <CardBody>
                  <p>Deseja excluir esse item?</p>

                  <BtnBox>
                    <BtnRed onClick={handleDelete}>
                      Excluir
                    </BtnRed>

                    <BtnWhite onClick={() => setVisibleModal(false)}>Cancelar</BtnWhite>
                  </BtnBox>
                </CardBody>
              </Content>
            </Content>
          </Card>
        </Modal>
      </>
    )
}

export default UpdateCarrouselReport
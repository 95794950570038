import Spinner from 'components/Spinner'

import { BtnOrange } from './styled'

const Component = props => {
    return (
        <BtnOrange disabled={props.isLoading} style={props.style} type={props.type} onClick={props.onClick} to={props.to}>
            {props.isLoading ? <Spinner /> : props.children}
        </BtnOrange>
    )
}

export default Component
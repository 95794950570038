import styled from 'styled-components';

export const CarouselContainer = styled.div`
    margin: 0 6px;
    max-width: 100%;
    min-width: 100%;
    padding: 0 50px;
    // height: 100%;
    position: relative;

     @media (max-width: 700px) {
        & {
            display: flex;
            justify-content: center;
            align-items: center;
        }

         @media (max-width: 425px) {
        & {
            margin: 0px;
            padding: 0 20px;
        }
`;

export const LeftArrowButton = styled.div`
    z-index: 4;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #B4B4B4;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        border-radius: 50%;
        cursor: pointer;
        background-color: #F0F0F0;
    }
         @media (max-width: 425px) {
        & {
            // position: static;
            // right: auto;                
            // top: auto;
            // transform: none;
        }
`;

export const RightArrowButton = styled.div`
    z-index: 4;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #B4B4B4;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        border-radius: 50%;
        cursor: pointer;
        background-color: #F0F0F0;
    }
`;

import React, { useState, useEffect } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import ReportCard from 'components/ReportCard';
import 'keen-slider/keen-slider.min.css';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CarouselContainer, LeftArrowButton, RightArrowButton } from './styled';

export const ReportCardCarousel = ({ reportsCarousel, updateFavorites }) => {
    const [slidesToMove, setSlidesToMove] = useState(2);
    const [sliderKey, setSliderKey] = useState(0);

    useEffect(() => {
        setSliderKey(prevKey => prevKey + 1);
    }, [updateFavorites]);

    useEffect(() => {
        const handleResize = () => {
            // if (window.innerWidth < 740) {
                setSlidesToMove(1);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial value

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [sliderRef, slider] = useKeenSlider({
        loop: false,
        mode: "snap",
        slides: {
            perView: "auto",
            spacing: "10",
        },
        drag: false, // habiltar para mobile
        controls: true,
        pagination: {
            clickable: true,
        },
        breakpoints: {
            "(max-width: 750px)": {
                drag: true,
            }
        }
    });

    const moveSlides = (direction) => {
        if (slider.current) {
            const currentIndex = slider.current.track.details.rel;
            const newIndex = currentIndex + direction * slidesToMove;
            slider.current.moveToIdx(newIndex);
        }
    };

    return (
        <CarouselContainer key={sliderKey}>
            <LeftArrowButton onClick={() => moveSlides(-1)}>
                <FaChevronLeft size={30}/>
            </LeftArrowButton>
            <div ref={sliderRef} className="keen-slider">
                {reportsCarousel.map(report => (
                    <div className="keen-slider__slide" key={report.id} style={{ minWidth: "250px", width: "250px" }}>
                        <ReportCard
                            name={report.name}
                            fileUrl={report.file_url}
                            linkTo={`/grupos/${report.group_id}/relatorios/${report.report_id}/datasets/${report.dataset_id}`}
                            reportId={report.id}
                            reportCardId={report.report_card_id}
                            isfavorite={report.favorite === 1 ? true : false}
                            updateFavorites={updateFavorites}
                        />
                    </div>
                ))}
            </div>
            <RightArrowButton onClick={() => moveSlides(1)}>
                <FaChevronRight size={30}/>
            </RightArrowButton>
        </CarouselContainer>
    );
}
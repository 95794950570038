import api from "./api";
import { setErrors } from "utils";


export const handleDepartmentShow = async () => (await api.get(`/departments`)).data

export const handleStoreDepartment = async (data, setMessages) => {
    const values = {
      department: data.department,
    };
  
    try {
     await api.post(`/departments/store`, values);
    } catch (error) {
      setMessages({ messages: setErrors(error), alert: "alert-orange" });
    }
  };

  export const handleUpdateDepartment = async (data, setMessages) => {
    const values = {
      department_id: data.department_id,
      department: data.department,
    };
  
    try {
     await api.put(`/departments/update`, values);
    } catch (error) {
      setMessages({ messages: setErrors(error), alert: "alert-orange" });
    }
  };
import { Card } from 'components/Card/styled'
import Modal from './index'
import { Content } from 'components/Content/styled'
import { CardBody } from 'components/CardBody/styled'
import { BtnBlue, BtnBox, BtnWhite } from 'components/Button/styled'

const ModalCapacity = ({header, button, visibleModal, setVisibleModal, clickFunction}) => {

return (
<Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
    <Card >
        <Content>
            <Content>
            <CardBody>
                <p style={{fontSize: "18px", fontWeight: "bold", marginBottom: "20px"
            }}>{header}</p>
                <BtnBox>
                    <BtnBlue onClick={clickFunction}>{button}</BtnBlue>
                    <BtnWhite onClick={() => setVisibleModal(false)}>Cancelar</BtnWhite>
                </BtnBox>
            </CardBody>
            </Content>
        </Content>
    </Card>
</Modal>
)
}

export default ModalCapacity
import { LinkBlue } from './styled'

const Component = props => {
    return (
        <LinkBlue to={props.to}
        onClick={props.onClick}>
            {props.children}
        </LinkBlue>
    )
}

export default Component
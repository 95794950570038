import { ButtonDepartment, ButtonsBox, ModalBox, ModalOverlay } from "./styled";
import Input from 'components/Form/LabelInput'

export const ModalNewCompany = props => {
    return (
      <>
        {props.isOpen && (
          <ModalOverlay>
            <ModalBox>
              <Input
              value=''
              name="company" 
              label="Nova Empresa"
              formik={props.formikModalCompany}
              />
              <ButtonsBox>
                <ButtonDepartment
                    onClick={props.onSubmit}>
                        Criar Empresa
                </ButtonDepartment>
                <ButtonDepartment 
                    onClick={props.onClose}>Fechar</ButtonDepartment>
              </ButtonsBox>
            </ModalBox>
          </ModalOverlay>
        )}
      </>
    );
  };
  
  export default ModalNewCompany;
import { ButtonDepartment, ButtonsBox, ModalBox, ModalOverlay } from "./styled";
import Input from 'components/Form/LabelInput'

export const ModalNewDepartment = props => {
    return (
      <>
        {props.isOpen && (
          <ModalOverlay>
            <ModalBox>
              <Input
              value=''
              name="department" 
              label="Novo Departamento"
              formik={props.formikModal}
              />
              <ButtonsBox>
                <ButtonDepartment 
                    onClick={props.onSubmit}>
                        Criar Departamento
                </ButtonDepartment>
                <ButtonDepartment 
                    onClick={props.onClose}>Fechar</ButtonDepartment>
              </ButtonsBox>
            </ModalBox>
          </ModalOverlay>
        )}
      </>
    );
  };
  
  export default ModalNewDepartment;
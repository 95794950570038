import { useContext } from 'react'
import { Redirect } from 'react-router-dom'

import { Context } from 'contexts/context'

const Page = () => {
  const { user } = useContext(Context)

//   if (user.roles === 'admin') return <Redirect to={'/grupos'} />

  return <h1>OIE</h1>
}

export default Page
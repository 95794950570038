import React, { useContext, useEffect, useState } from 'react';
import { CardLink, FavoritesTippy, StarIcon, StarIconWrapper, TooltipWrapper } from './style';
import 'tippy.js/dist/tippy.css';
import { handleUpdateFavoriteReport } from 'services/api/reports';
import { Context } from 'contexts/context';

const Component = ({ name, fileUrl, linkTo, reportId, reportCardId, isfavorite, updateFavorites }) => {

  const { favorites, setFavorites } = useContext(Context);
  const [isFavorite, setIsFavorite] = useState(favorites[reportCardId] !== undefined ? favorites[reportCardId] : isfavorite);

  useEffect(() => {
    setIsFavorite(favorites[reportCardId] !== undefined ? favorites[reportCardId] : isfavorite);
  }, [favorites, reportCardId, isfavorite]);

  const handleToggleFavorite = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newFavoriteStatus = !isFavorite;
    
    // Atualiza o estado local imediatamente
    setIsFavorite(newFavoriteStatus);

    // Aguarda a atualização no banco de dados
    await handleUpdateFavoriteReport(reportId, newFavoriteStatus, reportCardId);
    
    // Atualiza o estado global/contexto
    setFavorites((prevFavorites) => {
      const newFavorites = { ...prevFavorites };
      newFavorites[reportCardId] = newFavoriteStatus;
      return newFavorites;
    });

    // Chama a função de atualização dos favoritos
    updateFavorites();
  };

  return (
    <CardLink to={linkTo}>
      <StarIconWrapper onClick={handleToggleFavorite}>
        <FavoritesTippy content={isFavorite ? "Remover dos favoritos" : "Adicionar aos favoritos"} placement="top" arrow={true}>
          <TooltipWrapper>
            <StarIcon isfavorite={isFavorite} />
          </TooltipWrapper>
        </FavoritesTippy>
      </StarIconWrapper>
      <img src={fileUrl ? `${process.env.REACT_APP_URL_API}/${fileUrl}` : '/standardCard.png'} alt="analyticdi" />
      <p style={{ textAlign: 'center' }} data-content={name}>{name}</p>
    </CardLink>
  );
};

export default Component;
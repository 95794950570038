import { IconButtonContainer } from './styled'

export default function IconButton({ color, text, Icon, children, ...params }) {
    return (
        <IconButtonContainer type='button' color={color} {...params}>
            <Icon />
            <span>{text}</span>
        </IconButtonContainer>
    )
}


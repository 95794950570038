import api from "./api";
import { setErrors } from "utils";


export const handleCompaniesShow = async () => (await api.get(`/companies`)).data

export const handleStoreCompany = async (data, setMessages) => {
    const values = {
      company: data.company,
    };
  
    try {
     await api.post(`/companies/store`, values);
    } catch (error) {
      setMessages({ messages: setErrors(error), alert: "alert-orange" });
    }
  };

  export const handleUpdateCompany = async (data, setMessages) => {
    const values = {
      company_id: data.company_id,
      company: data.company,
    };
  
    try {
     await api.put(`/companies/update`, values);
    } catch (error) {
      setMessages({ messages: setErrors(error), alert: "alert-orange" });
    }
  };
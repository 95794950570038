import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import { FaChartBar } from "react-icons/fa"
import { useContext, useEffect, useState, useMemo } from 'react'
import { Context } from 'contexts/context'
import InputWithSearchIcon from 'components/InputSearch'
import { InputCadastrar } from 'pages/Cards/styled'

const Component = props => {
    const { setPainelTV } = useContext(Context)
    useEffect(() => {
        const fetchData = async () => {
            setPainelTV(true);
        };

        fetchData();
    }, []);

    const [filter, setFilter] = useState('');

    const filteredData = useMemo(() => {
        return props.data
            .filter(item => item.name.toLowerCase().includes(filter.toLowerCase()))
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [filter, props.data]);

    return (
        <>
            <TitleBar label="Relatórios" currentPage="" />
            <Content>
                <Card>
                    <CardTitle title="Workspaces">
                    <InputCadastrar>
                        <InputWithSearchIcon 
                            type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtrar por nome..." />
                        {/* <div style={{height: "47px"}}/> */}
                    </InputCadastrar>
                    </CardTitle>
                    
                    <Table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th width="50">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map(item =>
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>
                                        <SmLinkBlue to={`/grupos/${item.id}/relatorios`}>
                                            <FaChartBar />
                                        </SmLinkBlue>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card>
            </Content>
        </>
    )
}

export default Component
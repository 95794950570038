import { ReactDatePicker } from "./styled"

import { Label } from '../styled'

export default function DatePicker ({ label, name, formik }) {
    return (
        <div style={{ marginBottom: "16px", width: "100%" }}>
            <Label>
                <label htmlFor={name}>{label}</label>

                <div>
                    {formik.touched[name] && formik.errors[name] ? formik.errors[name] : null}
                </div>
            </Label>

            <ReactDatePicker 
                name={name} 
                selected={formik.values[name]} 
                onChange={date => formik.setFieldValue(name, date)}
                onKeyDown={event => { event.preventDefault() }}
            />
        </div>
    )
}
import api from "./api";
import { login, logout } from "../auth";
import { setErrors } from "utils";
import { handleFirm } from "./firms";

export const handleUsers = async () =>
  (await api.post(`/users/list-by-firm/users`)).data;

export const handleUsersByRoles = async (roles) =>
  (await api.post(`/users/list-by-firm-and-roles`, { roles })).data;

export const handleUserLogin = async (history, data, setMessages, firms, setFirm) => {
  try {
    const data_firm = { ...data, firms }
    const { token, user } = (await api.post(`/users/login`, data_firm)).data;
    
    login(token);

    const chooseFirm = await handleFirm(user.firm_id)
    setFirm(chooseFirm)

    if (user.roles === "admin") {
      return history.push(`/grupos`);
    }

    return history.push(`/relatorios`);
  } catch (error) {
    if (error.response) {
      setMessages({ messages: setErrors(error), alert: "alert-orange" });
      return
    }

    setMessages({ messages: 'Sistema inativo no momento!', alert: "alert-orange" });
  }
};

export const handleUserForgotPassword = async (data, setMessages) => {
  const values = {
    emailTo: data.email,
    forgotPasswordPageUrl: `${data.firm.domain}/recuperar-senha`,
    firm_domain: data.firm.domain
  };
  try {
    const response = await api.post(`/users/forgot-password`, values);
    setMessages({ messages: response.data.data.message, alert: "alert-green" });
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleUserRecoverPassword = async (
  history,
  token,
  data,
  setMessages
) => {
  try {
    await api.put(`/users/recover-password/${token}`, data);
    return history.push("/login");
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleStoreUser = async (history, data, setMessages, firm) => {
  const values = {
    name: data.name,
    email: data.email,
    roles: data.roles,
    department_id: Number(data.department_id),
    company_id: Number(data.company_id),
    blocked_user: data.blocked_user,
    // TO CHANGE APPS
    appName: firm.market_name,
    // appName: "Dato Tecnologia",
    // appName: "Atria Lub",
    // appName: "Ipel",
    // appName: "Assescont BI",
    appUrl: firm.domain,
  };

  try {
    await api.post(`/users/store-auth-user`, values);
    return history.push(`/usuarios`);
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleUpdateUser = async (history, data, userId, setMessages, domain, setIsSubmitting) => {
  try {

    await api.put(`/users/${userId}/update-auth-user`, {...data, domain});
    setIsSubmitting(false)
    return history.push(`/usuarios`);
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
    setIsSubmitting(false)
  }
};

export const handleUpdateViewReportOptions = async (data, userId) => {
  await api.put(`/users/${userId}/view-report-options`, data)
}


export const handleUserUpdateFile = async (data, id) => {
  const values = new FormData();
  values.append("file", data);
  try {
    await api.put(`/users/${id}/upload`, values);
    return;
  } catch (error) {
    return error;
  }
};

export const handleUserUpdatePassword = async (history, data, setMessages) => {
  try {
    await api.put(`/users/update-password`, data);
    // logout(history);
    // return;
    return history.push(`/`);
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleUpdateProfile = async (
  history,
  profileId,
  data,
  setMessages,
  firm_id
) => {
  const values = {
    id: profileId,
    name: data.name,
    email: data.email,
    firm_id
  };

  try {
    await api.put(`/users`, values);
    // logout(history);
    // return;
    return history.push(`/`);
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleUserShow = async (id) =>
  (await api.get(`/users/${id}`)).data;

export const getUserByPayload = async () => {
  try {
    const response = await api.get(`/users/payload`);
    return response.data; // Return the data from the response
  } catch (error) {
    throw error; // Re-throw the error to handle it in the calling function
  }
}

export const handleUserDelete = async (id, history) => {
  await api.delete(`/users/${id}`);
  return history.push("/usuarios");
};

export const updateCardsOpen = async (id, values) => {
  await api.put(`/users/${id}/cards-open`, values);
}

export const getUsersWithCard = async (reportCardId) => 
  (await api.get(`/users/users-with-report-card/${reportCardId}`)).data;

export const updateLastReportFilter = async (id, value) => {
  await api.put(`/users/${id}/update-last-report-filter`, value);
}
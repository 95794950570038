import ReactSelect from 'react-select'
import makeAnimated from 'react-select/animated';

import { Label, SelectLabel } from '../styled'

import { ItemsContainer, MoreItemsContainer } from './styled'

const animatedComponents = makeAnimated();

const colourStyles = {
    control: (styles) => ({ ...styles,
        marginTop: '8px',
        borderRadius: '20px',
        border: '1px solid var(--color-grey)',
        backgroundColor: 'var(--color-background-input)' ,
        color: 'var(--color-font)'
    }),
    input: (styles) => ({ ...styles, paddingLeft: '14px'}),
    placeholder: (styles) => ({ ...styles, padding: '0 14px' }),
    singleValue: (styles) => ({ ...styles, paddingLeft: '14px', color: 'var(--color-font)'})
  };

export default function SelectDepartment ({ label, name, isMulti, options, formik, moreItems, onChange, defaultValue}) {
    return (
        <>
            <SelectLabel sclassName="flexGrow">
                <ItemsContainer>
                    <Label>
                        <label htmlFor={name}>{label}</label>

                        <div>
                            {formik.touched[name] && formik.errors[name] ? formik.errors[name] : null}
                        </div>
                    </Label>

                    <MoreItemsContainer>{moreItems}</MoreItemsContainer>
                    
                </ItemsContainer>
                
                <ReactSelect
                    defaultValue={defaultValue}
                    placeholder="Selecione..."
                    noOptionsMessage={() => "Sem opções"}
                    name={name} 
                    closeMenuOnSelect={true}
                    // components={animatedComponents}
                    isMulti={isMulti} 
                    options={options}
                    styles={colourStyles}
                    value={formik.values[name]}
                    onChange={(result) => {
                        formik.setFieldValue(name, result);
                      }}
                />
            </SelectLabel>
        </>
    )
}
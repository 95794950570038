import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

const AnalyticsSkeleton = () => {
    return (
        <tbody>
            {Array.from({ length: 10 }).map(index => (
                <tr key={index}>
                    <td>
                        <Skeleton height={20} width={60}  />
                    </td>
                    
                    <td>
                        <Skeleton height={20} width={150}  />
                    </td>

                    <td>
                        <Skeleton height={20} width={200}  />
                    </td>

                    <td>
                        <Skeleton height={20} width={150}  />
                    </td>

                    <td>
                        <Skeleton height={20} width={150}  />
                    </td>

                    <td>
                        <Skeleton height={20} width={150}  />
                    </td>
                </tr>
            ))}
        </tbody>

    )
}

export default AnalyticsSkeleton


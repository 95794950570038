import api from './api'

export const handleAnalyticsUsersActionsReports = async ({ term, initialDate, endDate }) => {
    return (await api.get(`/users-actions/reports/by-firm/analytics?term=${term}&initialDate=${initialDate}&endDate=${endDate}`)).data
}

export const handleAnalyticsUsersActionsReportsXlsx = async (data) => {
    return (await api.post(`/users-actions/reports/download/xlsx`, data, { responseType: "blob" })).data
}

export const handleAnalyticsUsersActionsReportsPdf = async (data) => {
    return (await api.post(`/users-actions/reports/download/pdf`, data, { responseType: "blob" })).data
}

export const handleListUsersActionsReports = async ({ term, initialDate, endDate, currentPage = 1, perPage = 10 }) => {
    return (await api.get(`/users-actions/reports/by-firm?term=${term}&currentPage=${currentPage}&perPage=${perPage}&initialDate=${initialDate}&endDate=${endDate}`)).data
}

export const handleCreateUserActionReport = async (data) => {
    return (await api.post(`/users-actions/reports`, data)).data
}

export const handleUpdateUserActionReport = async (id) => {
    return (await api.put(`/users-actions/reports/${id}`)).data
}

import ReactSelect from 'react-select'
import makeAnimated from 'react-select/animated';

import { Label, SelectLabel } from '../styled'

import { ItemsContainer, MoreItemsContainer } from './styled'

const animatedComponents = makeAnimated();

const colourStyles = {
    control: (styles) => ({ ...styles,
        marginTop: '8px',
        borderRadius: '20px',
        border: '1px solid var(--color-grey)',
        backgroundColor: 'var(--color-background-input)' ,
        color: 'var(--color-font)'
    }),
    input: (styles) => ({ ...styles, paddingLeft: '14px' }),
    placeholder: (styles) => ({ ...styles, padding: '0 14px' }),
    singleValue: (styles) => ({ ...styles, paddingLeft: '14px', color: 'var(--color-font)' })
  };

export default function Select ({ label, name, isMulti, options, formik, moreItems, defaultValue}) {
    return (
        <>
            <SelectLabel className="flexGrow">
                <ItemsContainer>
                    <Label>
                        <label htmlFor={name}>{label}</label>

                        <div>
                            {formik.touched[name] && formik.errors[name] ? formik.errors[name] : null}
                        </div>
                    </Label>

                    <MoreItemsContainer>{moreItems}</MoreItemsContainer>
                    
                </ItemsContainer>
                
                <ReactSelect 
                    placeholder="Selecione..."
                    noOptionsMessage={() => "Sem opções"}
                    name={name} 
                    closeMenuOnSelect={!isMulti} // Close menu on select if not multi
                    components={animatedComponents} // Uncomment if you want animations
                    isMulti={isMulti} 
                    options={options}
                    styles={formik.values[name] !== '' ? colourStyles : { ...colourStyles, singleValue: (styles) => ({ ...styles, paddingLeft: '14px', color: "grey" }) } }
                    value={isMulti ? formik.values[name] : options.find(option => option.value === formik.values[name]) || defaultValue} // Handle multi vs single
                    onChange={(selected) => {
                        if (isMulti) {
                            formik.setFieldValue(name, selected); // Set selected array for multi
                        } else {
                            formik.setFieldValue(name, selected ? selected.value : ''); // Set single value or empty
                        }
                    }}
                />
            </SelectLabel>
        </>
    )
}
import { useContext } from 'react'
import { Redirect } from 'react-router-dom'

import { Context } from 'contexts/context'

const Page = () => {
  const { user } = useContext(Context)

  if (user.roles === 'admin') return <Redirect to={'/grupos'} />

  return <Redirect to={'/relatorios'} />
}

export default Page
import api from './api'
import { setErrors } from 'utils'

export const getGroupsWithCard = async (reportCardId) => 
  (await api.get(`/collections/groups-with-report-card/${reportCardId}`)).data;

export const deleteGrupAccessToReport = async (userGroupReports) => {
  const userGroupId = userGroupReports.group_id
  const userGroupReportsId = userGroupReports.reports

  await api.post(`/collections/user-groups-reports/${userGroupId}`, { reports: userGroupReportsId })
}

export const getGroupsByUser = async (userId) =>
  (await api.get(`/collections/reports/${userId}`)).data

export const removeUserFromGroup = async (data) => {
    await api.post('/collections/remove-user-from-group', data);
}

export const addUserToGroup = async (data, history, setMessages) => {
  try {
    await api.post('/collections/add-user-to-group', data)

    return history.push(`/usuarios/${data.user_id}/relatorios`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}
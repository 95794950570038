import React from 'react'
import { Container, Table } from './styled'

const Component = props => {

    return (
        <Container>
            <Table>
                {props.children}
            </Table>
        </Container>
    )
}

export default Component
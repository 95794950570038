import styled from 'styled-components'

export const Body = styled.div`
    display: flex;
    // background-color: var(--color-background);
    // align-items: center;
    height: 100dvh;
`   
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.topBarShow ? '60px' : '0px'};
    flex-grow: 1;    
    height: auto;

    // @media (min-width: 1141px) {
    //     margin-left: ${props => props.toggle ? '180px' : '80px'}; 
    // }
`
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-left: 3em; */
    flex-grow: 1;
`

export const Notification = styled.div`
    position: fixed;
    max-width: 310px;
    bottom: 50px;
    right: 30px;
    background-color: white;
    color: var(--color-orange);
    padding: 15px 20px;
    border-radius: 20px;
    display: ${props => (props.visible ? 'block' : 'none')};
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
    `;
import ReactSelect from 'react-select'
import makeAnimated from 'react-select/animated';

import { Label, SelectLabel } from '../styled'

import { ItemsContainer, MoreItemsContainer } from './styled'

const animatedComponents = makeAnimated();

const customStyles = {
    control: (styles) => ({ ...styles,
        marginTop: '8px',
        borderRadius: '20px',
        border: '1px solid var(--color-grey)',
        backgroundColor: 'var(--color-background-input)' ,
        color: 'var(--color-font)'
    }),
    input: (styles) => ({ ...styles, paddingLeft: '14px' }),
    placeholder: (styles) => ({ ...styles, padding: '0 14px' }),
    singleValue: (styles) => ({ ...styles, paddingLeft: '14px' })
  };

  export default function SelectOptions({ label, name, isMulti, options, value, onChange, moreItems }) {
    return (
      <>
        <SelectLabel className="flexGrow">
          <ItemsContainer>
            <Label>
              <label htmlFor={name}>{label}</label>
              <div>{/* Aqui você pode adicionar erros ou mensagens relevantes se desejar */}</div>
            </Label>
            <MoreItemsContainer>{moreItems}</MoreItemsContainer>
          </ItemsContainer>
          <ReactSelect
            placeholder="Selecione..."
            noOptionsMessage={() => "Sem opções"}
            name={name}
            closeMenuOnSelect={true}
            isMulti={isMulti}
            options={options}
            styles={customStyles}
            value={value}
            onChange={onChange}
          />
        </SelectLabel>
      </>
    );
  }
import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import BtnOrange from 'components/Button/BtnOrange'
import BtnRed from 'components/Button/BtnRed'
import BtnBox from 'components/Button/BtnBox'
import BtnWhite from 'components/Button/BtnWhite'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import Modal from 'components/Modal'
import SelectLabel from 'components/Form/LabelSelect'
import Select from 'components/Form/Select'
import CardBody from 'components/CardBody'
import SelectDepartment from 'components/Form/SelectDepartment'
import IconButton from 'components/Button/IconButton'
import { useContext, useEffect, useState } from 'react'
import { RiAddLine, RiPencilLine, RiQuestionLine } from 'react-icons/ri'
import { handleDepartmentShow } from 'services/api/department'
import ModalNewDepartment from 'components/NewDepartment'
import ModalEditDepartment from 'components/NewDepartment/EditDepartment'
import { Context } from 'contexts/context'
import { handleCompaniesShow } from 'services/api/companies'
import ModalNewCompany from 'components/NewCompany'
import ModalEditCompany from 'components/NewCompany/EditCompany'
import Checkbox from 'components/Form/LabelCheckbox'
import { HelpTippy } from 'components/CardTitle/styled'

const Component = props => {
  const breadcrumb = [{ path: '/usuarios', label: 'Index' }]
  const [data, setData] = useState([])
  const [companies, setCompanies] = useState([])
  const [newDepartment, setNewDepartment] = useState(false);
  const [editDepartment, setEditDepartment] = useState(false)
  const [newCompany, setNewCompany] = useState(false);
  const [editCompany, setEditCompany] = useState(false)
  const { user: { firm_id } } = useContext(Context)
  
  async function fetchData() {
    setCompanies(await handleCompaniesShow())
    setData(await handleDepartmentShow())
    setCompanies(await handleCompaniesShow())
  }

//   async function fetchData() {
//     let companiesData;
//     let departmentsData;

//     try {
//   } catch (error) {
//       return;
//   }

//     try {
//         companiesData = await handleCompaniesShow();
//     } catch (error) {
//         console.error('Erro ao buscar dados das empresas:', error);
//         return;
//     }

//     try {
//         departmentsData = await handleDepartmentShow();
//     } catch (error) {
//         console.error('Erro ao buscar dados dos departamentos:', error);
//         return;
//     }

//     try {
//       companiesData = await handleCompaniesShow();
//   } catch (error) {
//       console.error('Erro ao buscar dados das empresas:', error);
//       return;
//   }
    

//     setCompanies(companiesData);
//     setData(departmentsData);
//     setCompanies(companiesData);
// }
  
  useEffect(async() => {
    await fetchData()
      }, [newDepartment, editDepartment, newCompany, editCompany]);

  function departmentModal() {
    setNewDepartment(true);
  }

  function departmentModalEdit() {
    setEditDepartment(true);
  }

  function companyModal() {
    setNewCompany(true);
  }

  function companyModalEdit() {
    setEditCompany(true);
  }

  const closeModalAndReload = async () => {
    setNewDepartment(false);
    setEditDepartment(false);
    fetchData()
  }

  const closeModalCompanyAndReload = async () => {
    setNewCompany(false);
    setEditCompany(false);
    fetchData()
  }

  async function submitModalNew (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  async function submitModalEdit (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  async function submitModalNewCompany (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalCompanyAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  async function submitModalEditCompany (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalCompanyAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  const handleCheckboxClickBlocked = () => {
    const newValue = !props.formik.values.blocked_user ? 1 : 0;
    props.formik.setFieldValue('blocked_user', newValue);
  };

  const handleCheckboxClickActive = () => {
    const newValue = !props.formik.values.inactive ? 1 : 0;
    props.formik.setFieldValue('inactive', newValue);
  };

  return (
    <>
      <TitleBar label="Usuários" currentPage="" />
      <Content>
        <Card>
          <CardTitle title="Editar">
            {/* <BtnOrange onClick={() => props.setVisibleModal(true)}>Excluir</BtnOrange> */}
          </CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={props.formik.handleSubmit}>
                <Messages formMessages={props.messages.messages} alert={props.messages.alert} />
                <Input name="name" label="Nome" formik={props.formik} />
                <Input name="email" label="Email" formik={props.formik} />
                {/* <Select
                  name="roles"
                  label="Permissão"
                  formik={props.formik}
                >
                  <option value="">Selecione...</option>
                  <option value="admin">Administrador</option>
                  <option value="user">Usuário</option>
                </Select> */}
                <Select
                  name="roles"
                  label="Permissão"
                  formik={props.formik}
                  defaultValue={props.formik.initialValues.roles}
                  options={[
                    {value: 'admin', label: 'Administrador'},
                    {value: 'user', label: 'Usuário'}
                  ]}

                />
                <SelectDepartment
                  name="department_id"
                  label="Departamento"
                  value=''
                  defaultValue={{
                    value: props.formik.initialValues.department_id.value,
                    label: props.formik.initialValues.department_id.label
                  }}
                  formik={props.formik}
                  moreItems={[
                    <div key={0}>
                      <IconButton 
                        Icon={RiAddLine} 
                        color='#082127' 
                        text='Criar'
                        onClick={departmentModal}
                        style={{marginLeft: "10px"}}
                      />
                    </div>,
                    <div key={1}>
                    <IconButton 
                      Icon={RiPencilLine} 
                      color='#082127' 
                      text='Editar'
                      onClick={departmentModalEdit}
                      style={{marginLeft: "10px"}}
                    />
                  </div>
                  ]}
                  options={[
                    {
                      value: '',
                      label: 'Sem departamento'
                    },
                     ...data.map((d) => ({ value: d.department_id, label: d.description }))
                  ]}
                    />
                {
                // firm_id === 11
                (firm_id === 11 || firm_id === 19 || firm_id === 20) 
                && (
                  <SelectDepartment
                    name="company_id"
                    label="Empresa"
                    value=''
                    defaultValue={{
                      value: props.formik.initialValues.company_id.value,
                      label: props.formik.initialValues.company_id.label
                    }}
                    formik={props.formik}
                    moreItems={[
                      <div key={0}>
                        <IconButton 
                          Icon={RiAddLine} 
                          color='#082127' 
                          text='Criar'
                          onClick={companyModal}
                          style={{marginLeft: "10px"}}
                        />
                      </div>,
                      <div key={1}>
                      <IconButton 
                        Icon={RiPencilLine} 
                        color='#082127' 
                        text='Editar'
                        onClick={companyModalEdit}
                        style={{marginLeft: "10px"}}
                      />
                    </div>
                    ]}
                    options={[
                      {
                        value: '',
                        label: 'Sem empresa'
                      },
                       ...companies.map((d) => ({ value: d.company_id, label: d.description }))
                    ]}
                  />
                  )}
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Checkbox
                      name='blocked_user'
                      label='Usuário Bloqueado'
                      formik={props.formik}
                      checked={props.formik.values.blocked_user}
                      onClick={handleCheckboxClickBlocked}
                    />
                    <HelpTippy 
                      content="Bloqueia o usuário temporariamente, impedindo o acesso ao sistema. Ainda aparecerá na lista de usuários." 
                      placement="right"
                      arrow={false}
                      >
                      <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <RiQuestionLine size={20}/>
                      </div>
                    </HelpTippy>
                  </div>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Checkbox 
                      name='inactive'
                      label='Usuário Inativo'
                      formik={props.formik}
                      checked={props.formik.values.inactive}
                      onClick={handleCheckboxClickActive}
                    />
                    <HelpTippy 
                      content="Inativa o usuário permanentemente, impedindo o acesso ao sistema. Não aparecerá na lista de usuários ativos."
                      placement="right"
                      arrow={false}
                      >
                      <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <RiQuestionLine size={20}/>
                      </div>
                    </HelpTippy>
                  </div>
                <BtnBox>
                  <BtnBlue type="submit" disabled={props.isSubmitting}>
                    {props.isSubmitting? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
      <ModalNewDepartment 
        formMessages={props.messages.messages}
        isOpen={newDepartment} 
        onClose={() => {
          setNewDepartment(false)
          props.formikModal.resetForm()
          // reloadPage()
          fetchData()
        }} 
        onSubmit={
          async (values) => { 
            await submitModalNew(props.formikModal, values)
        }}
        formikModal={props.formikModal}
      />
      <ModalEditDepartment
          formMessages={props.messages.messages}
          isOpen={editDepartment} 
          onClose={() => {
            setEditDepartment(false)
            props.formikModalEdit.resetForm()
            // reloadPage()
            fetchData()
          }} 
          onSubmit={
            async (values) => { 
              await submitModalEdit(props.formikModalEdit, values)
          }}
          formikModalEdit={props.formikModalEdit}
          data={data}
          />

      <ModalNewCompany 
          formMessages={props.messages.messages}
          isOpen={newCompany} 
          onClose={() => {
            setNewCompany(false)
            props.formikModalCompany.resetForm()
            // reloadPage()
            fetchData()
          }} 
          onSubmit={
            async (values) => { 
              await submitModalNewCompany(props.formikModalCompany, values)
          }}
          formikModalCompany={props.formikModalCompany}
      />
      <ModalEditCompany
          formMessages={props.messages.messages}
          isOpen={editCompany} 
          onClose={() => {
            setEditCompany(false)
            props.formikModalCompanyEdit.resetForm()
            // reloadPage()
            fetchData()
          }} 
          onSubmit={
            async (values) => {
              await submitModalEditCompany(props.formikModalCompanyEdit, values)
          }}
          formikModalCompanyEdit={props.formikModalCompanyEdit}
          companies={companies}
          />
      {/* <Modal visibleModal={props.visibleModal} setVisibleModal={props.setVisibleModal}>
        <Card>
          <Content>
            <Content>
              <CardBody>
                <p>Deseja excluir esse item?</p>
                <BtnBox>
                  <BtnRed onClick={props.handleDelete}>Excluir</BtnRed>
                  <BtnWhite onClick={() => props.setVisibleModal(false)}>Cancelar</BtnWhite>
                </BtnBox>
              </CardBody>
            </Content>
          </Content>
        </Card>
      </Modal> */}
    </>
  )
}

export default Component
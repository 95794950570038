import React, { useState } from 'react';
import { NavContainer, NavButton, Icon } from './styled';
import { FaUserTimesIcon, FaUserCheckIcon } from './styled';

const UsersFilters = ({ onNavClick }) => {
  const [selected, setSelected] = useState('ativos')

  const handleNavClick = (type) => {
    setSelected(type);
    onNavClick(type);
  };

  return (
    <NavContainer>
      <NavButton selected={selected === 'ativos'} onClick={() => handleNavClick('ativos')}>
        <Icon selected={selected === 'ativos'}><FaUserCheckIcon /></Icon>
        <h1>Ativos</h1>
      </NavButton>
      <NavButton selected={selected === 'inativos'} onClick={() => handleNavClick('inativos')}>
        <Icon selected={selected === 'inativos'}><FaUserTimesIcon /></Icon>
        <h1>Inativos</h1>
      </NavButton>
    </NavContainer>
  );
};

export default UsersFilters;
import { Context } from 'contexts/context';
import React, { useContext, useEffect } from 'react';

function BaseComponent({ children }) {

    const { firm } = useContext(Context);

    useEffect(() => {
      // FIRM TO CHANGE
      if (!firm) return;
        if (firm && firm.name) {
            // TO CHANGE APP
            // if(firm.domain === 'localhost'){
            // if(firm.domain === 'testeapp.datotecnologia.com.br'){
            if(firm.domain === 'app.datotecnologia.com.br'){

              document.title = 'Dato Hub'
            }else{
              document.title = `Dato Hub - ${firm.market_name}`;
            }
            const favicon = document.getElementById('dynamic-favicon');
            if (favicon) {
              if (firm.file_favicon) {
                favicon.href = `${process.env.REACT_APP_URL_API}/${firm.file_favicon}`; // Ensure the firm object has a favicon property
              } else {
                // Default favicon
                favicon.href = `/favicon-dato.ico`;
              }
            }
        }
    }, [firm]);

    if (!firm || !firm.name) return null;
  
    return (
      <div>
        {children}
      </div>
    );
}
  
export default BaseComponent;
import styled from 'styled-components'

export const Content = styled.div`
    width: 100%;
    padding: 0 15px 15px 15px;

    // @media (max-width: 500px) {
    //     display: flex;
    //     justify-content: center;
    //     padding: 0;
    // }
`
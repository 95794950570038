import styled, { css } from 'styled-components'
import Tippy from '@tippyjs/react';
import { FaAngleDoubleRight, FaAngleDoubleLeft, FaChevronDown, FaChevronUp   } from 'react-icons/fa';

const numero_proibido = 12 + 1

export const MenuLeftContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${({ menuExpanded }) => (menuExpanded ? '250px' : '44px')};
    padding: ${numero_proibido}px;
    border-right: 2px solid var(--color-grey);
    transition: width 0.5s ease;
    font-size: ${numero_proibido}px;
`;

export const MenuText = styled.span`
    transition: opacity 0.3s ease;
    opacity: ${({ menuExpanded }) => (menuExpanded ? '1' : '0')};
    transition-delay: 0.5s;
    font-size: 14px;

    opacity: ${({ showText }) => (showText ? '1' : '0')};
    transition: opacity 0.3s ease;
`;

export const UsersGroup = styled.div`
    padding: 4px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;


    opacity: ${({ showText }) => (showText ? '1' : '0')};
    transition: opacity 0.3s ease;
`;

export const UsersReport = styled.div`
    position: relative; /* Para posicionar o pseudo-elemento corretamente */
    padding: 5px;
    padding-left: 15px;
    margin-left: 10px;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
    cursor: pointer;

    &:hover {
        background-color: #F2FAFD;
        ${({ selected }) => selected && `
            background-color: #F2FAFD;
        `}
    }

    ${({ selected }) => selected && `
        background-color: #F2FAFD;
        var(--color-background-report-card);
    `}

    opacity: ${({ showText }) => (showText ? '1' : '0')};
    transition: opacity 0.3s ease;

    ::before {
        content: '';
        position: absolute;
        top: 22%;
        left: 4%;
        width: 0.5px;
        height: 56%;
        background-color: var(--color-orange);
        border: 2px solid var(--color-orange);
        // background-color: grey;
        // border: 2px solid grey;
        // background-color: #eabc1b;
        // border: 2px solid #eabc1b;
        border-radius: 10px;
        display: ${({ selected }) => (selected ? 'block' : 'none')};
    }
`;

export const DoubleLeftArrow = styled(FaAngleDoubleLeft)`
    transition: color 0.3s ease;
    cursor: pointer;
  
    &:hover {
    color: grey;
    }
`

export const DoubleRightArrow = styled(FaAngleDoubleRight)`
    transition: color 0.3s ease;
    cursor: pointer;
  
    &:hover {
    color: grey;
    }
`

export const DownArrow = styled(FaChevronDown)`
    transition: color 0.3s ease;
    cursor: pointer;
  
    &:hover {
    color: grey;
    }
`

export const UpArrow = styled(FaChevronUp)`
    transition: color 0.3s ease;
    cursor: pointer;
  
    &:hover {
    color: grey;
    }
`
import styled from 'styled-components'
import { BsSearch } from 'react-icons/bs'

export const StyledInput = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 47px;
  
  // @media(max-width: 500px) {
  //   margin-bottom: 20px;
  // }
`;

export const InputField = styled.input`
  padding: 8px 35px 8px 10px; /* Adicionando espaço para o ícone de lupa no lado direito */
  border-radius: 5px;
  border: 1px solid var(--color-grey);
  background-color: var(--color-background-input);
  color: var(--color-green);
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: 2px solid var(--color-green);
    color: var(--color-green);
  }
`;

export const SearchIcon = styled(BsSearch)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #666;
`;
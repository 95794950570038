import { useEffect, useState } from 'react'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import LinkBlue from 'components/Button/LinkBlue'
import Container from 'components/Container'
import ReportCard from 'components/ReportCard/ReportCardAdmin'

import { handleReportCardsByFirm } from 'services/api/reportCards'
import InputWithSearchIcon from 'components/InputSearch'
import { DepartmentContainer, DepartmentsContainer, InputCadastrar, SelectDepartmentContainer } from './styled'
import { FaAngleDown, FaAngleRight } from 'react-icons/fa'

const Page = () => {
  const [data, setData] = useState([])

  // useEffect(() => {
  //   (async () => {
  //     setData(await handleReportCardsByFirm())
  //   })()
  // }, [])

  useEffect(() => {
    const fetchData = async () => {
      const reportCardsData = await handleReportCardsByFirm();
      setData(reportCardsData);

      // Inicializa expandedContainers com cada departamento como chave e true como valor
      expandAllGroups(reportCardsData)
    };

    fetchData();
  }, []);

  const [filter, setFilter] = useState('');
  const [expandedContainers, setExpandedContainers] = useState({});

  function expandAllGroups(reportCardsData){
    const initialExpandedContainers = {};
    reportCardsData.forEach(item => {
      initialExpandedContainers[item.department_description] = true;
    });
    setExpandedContainers(initialExpandedContainers);
  }

  // const filteredData = data.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()));

  function includesFilter(value, filter) {
    return value && value.toLowerCase().includes(filter.toLowerCase());
}

const filteredData = data.filter(item =>
  includesFilter(item.name, filter) ||
  includesFilter(item.department_description, filter)
)
.sort((a, b) => a.name.localeCompare(b.name));

  const groupedData = filteredData.reduce((groups, item) => {
    const departmentId = item.department_description || 'null';
    if (!groups[departmentId]) {
      groups[departmentId] = [];
    }
    groups[departmentId].push(item);
    return groups;
  }, {});

  const toggleExpansion = departmentId => {
    setExpandedContainers(prevState => ({
      ...prevState,
      [departmentId]: !prevState[departmentId]
    }));
  };

  const departmentIds = Object.keys(groupedData);
  const sortedDepartments = departmentIds.filter(departmentId => departmentId !== "null").sort();
  const otherDepartment = departmentIds.find(departmentId => departmentId === "null");

  const sortedDepartmentIds = otherDepartment ? sortedDepartments.concat(otherDepartment) : sortedDepartments;

  return (
    <>
      <TitleBar label="Cards" currentPage="" />

      <Content>
        <Card>
          <CardTitle title="Listagem" style={{display:"flex", flexDirection:"row"}}>
           <InputCadastrar >
                <InputWithSearchIcon 
                type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtrar por nome..." />
                
                <LinkBlue
                className="cards-cadastrar"
                to="/cards/cadastrar">Cadastrar</LinkBlue>
            </InputCadastrar>
          </CardTitle>
          
          
          <Container>
            {/* {Object.keys(groupedData).map(departmentId => (
              <DepartmentsContainer key={departmentId}>
                  {departmentId !== "null" ? <section>{departmentId}</section> : <section>Outros</section>}
                <DepartmentContainer>

                {groupedData[departmentId].map(item => (
                  <ReportCard 
                    key={item.id} 
                    name={item.name} 
                    fileUrl={item.file_url} 
                    linkTo={`/cards/${item.id}/editar`}
                    />
                ))}
                </DepartmentContainer>
                
              </DepartmentsContainer>              
            ))} */}

              {sortedDepartmentIds.map(departmentId => (
                <DepartmentsContainer key={departmentId}>
                  {departmentId !== "null" ? (
                    <SelectDepartmentContainer onClick={() => toggleExpansion(departmentId)}>
                      <section>{departmentId}</section>
                      <button>
                        {expandedContainers[departmentId] ? 
                          <span><FaAngleDown size={26} /></span> : 
                          <span><FaAngleRight size={26} /></span>}
                      </button>
                  </SelectDepartmentContainer>
                  ) : (
                    <SelectDepartmentContainer onClick={() => toggleExpansion(departmentId)}>
                      <section>Outros</section>
                      <button>
                        {expandedContainers[departmentId] ? 
                          <span><FaAngleDown size={26} /></span> : 
                          <span><FaAngleRight size={26} /></span>}
                      </button>
                    </SelectDepartmentContainer>
                  )}
                  <DepartmentContainer style={{ maxHeight: expandedContainers[departmentId] ? 'none' : '0px', overflow: 'hidden' }}>
                    {groupedData[departmentId].map(item => (
                      <ReportCard 
                        key={item.id} 
                        name={item.name} 
                        fileUrl={item.file_url} 
                        linkTo={`/cards/${item.id}/editar`}
                      />
                    ))}
                  </DepartmentContainer>
                  
                </DepartmentsContainer>
              ))}

          </Container>
        </Card>
      </Content>
    </>
  )
}

export default Page

import SelectDepartment from "components/Form/SelectDepartment";
import { ButtonDepartment, ButtonsBox, ModalBox, ModalOverlay } from "./styled";
import Input from 'components/Form/LabelInput'

export const ModalEditDepartment = props => {
    return (
      <>
        {props.isOpen && (
          <ModalOverlay>
            <ModalBox>
            <SelectDepartment
              name="department_id"
              label="Departamento"
              value=''
              formik={props.formikModalEdit}
              options={[
               ...props.data.map((d) => ({ value: d.department_id, label: d.description }))
              ]}
            />
              <Input
              value=''
              name="department" 
              label="Novo nome do departamento"
              formik={props.formikModalEdit}
              />
              <ButtonsBox>
                <ButtonDepartment 
                    onClick={props.onSubmit}
                    type="submit">
                        Salvar edição
                </ButtonDepartment>
                <ButtonDepartment 
                    onClick={props.onClose}>Fechar</ButtonDepartment>
              </ButtonsBox>
            </ModalBox>
          </ModalOverlay>
        )}
      </>
    );
  };
  
  export default ModalEditDepartment;
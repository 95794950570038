import { Card } from "components/Card/styled"
import { Content } from "components/Content/styled"
import Modal from 'components/Modal'
import { CardBody } from "components/CardBody/styled"
import { BtnBox, BtnRed, BtnWhite } from "components/Button/styled"


const ModalCards = props =>  {
    return(
    <Modal visibleModal={props.visibleModal} setVisibleModal={props.setVisibleModal}>
        <Card>
          <Content>
            <Content>
              <CardBody>
                <p>
                    {/* Deseja excluir esse item? */}
                    {props.excludeItemMessage}
                    </p>
                <BtnBox>
                  <BtnRed onClick={
                    // handleDelete
                    props.handleDelete
                    }>                    
                    {/* Excluir */}
                    {props.excludeMessage}
                    </BtnRed>
                  <BtnWhite onClick={() => 
                    // setVisibleModal(false)
                    props.setVisibleModal(false)
                    }>Cancelar</BtnWhite>
                </BtnBox>
              </CardBody>
            </Content>  
          </Content>
        </Card>
      </Modal>
    )
}

export default ModalCards
import Modal from "components/Modal"
import Spinner from "components/Spinner"

export default function Component({ loading }) {
    return (
        <Modal visibleModal={loading} setVisibleModal={null}>
            <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgb(0,0,0, 0.8)" }}>
                <img style={{ width: '120px', height: '120px' }} src="/loading.gif" alt="Loading..."></img>
                {/* <Spinner /> */}
            </div>
        </Modal>
    )
}
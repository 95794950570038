import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Validation from './validation'
import { handleUserShow, handleUpdateUser, handleUserDelete } from 'services/api/users'
import Component from './Component'
import { handleStoreDepartment, handleUpdateDepartment } from 'services/api/department'
import { handleStoreCompany, handleUpdateCompany } from 'services/api/companies'
import { Context } from 'contexts/context'

const Page = () => {

    const history = useHistory()
    const params = useParams()
    const [messages, setMessages] = useState({ messages: [], alert: '' })
    const [data, setData] = useState({})
    const [visibleModal, setVisibleModal] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { firm } = useContext(Context)

    // useEffect(() => {
    //     (async () => {
    //       const userData = await handleUserShow(params.id);
    //       setData(userData)
    //     })();
    //   }, [params.id]);

    useEffect(() => {
        const fetchData = async () => {
          const userData = await handleUserShow(params.id);
          setData(userData);
        };
      
        fetchData();
      
      }, [params.id]);
    const formik = useFormik({
        initialValues: { 
            name: data.name,
            email: data.email,
            roles: data.roles,
            department_id: {
                value: data.department_id,
                label: data.department ? data.department.description : 'Sem departamento'
          },
            company_id: {
                value: data.company_id,
                label: data.company ? data.company.description : 'Sem empresa'
      },
            blocked_user: data.blocked_user,
            inactive: data.inactive
        },
        validationSchema: Yup.object(Validation(Yup)),
        onSubmit: async () => {
            setIsSubmitting(true)
            formik.values.department_id = formik.values.department_id.value
            formik.values.company_id = formik.values.company_id.value
            handleUpdateUser(history, formik.values, params.id, setMessages, firm.domain, setIsSubmitting)
        },
        enableReinitialize: true
    })

    const handleDelete = async () => await handleUserDelete(params.id, history)

    const handleResetForm = (formik) => {
        formik.resetForm();
      };

    const formikModal = useFormik({
        initialValues: { department: '' },
        onSubmit: async () => {
            handleStoreDepartment(formikModal.values, setMessages)
            handleResetForm(formikModal)
        }
        })

    const formikModalEdit = useFormik({
        initialValues: { department_id: '' },
        onSubmit: async () => {
            formikModalEdit.values.department_id = formikModalEdit.values.department_id.value
            handleUpdateDepartment(formikModalEdit.values, setMessages)
            handleResetForm(formikModalEdit)
        }
        })  

    const formikModalCompany = useFormik({
        initialValues: { company: '' },
        onSubmit: async () => {
            handleStoreCompany(formikModalCompany.values, setMessages)
            handleResetForm(formikModalCompany)
        }
        })
        
    const formikModalCompanyEdit = useFormik({
        initialValues: { company_id: '' },
        onSubmit: async () => {
            formikModalCompanyEdit.values.company_id = formikModalCompanyEdit.values.company_id.value
            handleUpdateCompany(formikModalCompanyEdit.values, setMessages)
            handleResetForm(formikModalCompanyEdit)
        }
        })

    return <Component
        setVisibleModal={setVisibleModal}
        visibleModal={visibleModal}
        formik={formik}
        formikModal={formikModal}
        formikModalEdit={formikModalEdit}
        formikModalCompany={formikModalCompany}
        formikModalCompanyEdit={formikModalCompanyEdit}
        messages={messages}
        handleDelete={handleDelete}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
    />
}

export default Page
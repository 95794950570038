import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import SelectDepartment from 'components/Form/SelectDepartment'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'
import Checkbox from 'components/Form/LabelCheckbox'
import { useContext, useEffect, useState } from 'react'
import { handleDepartmentShow } from 'services/api/department'
import IconButton from 'components/Button/IconButton'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { ModalNewDepartment } from 'components/NewDepartment'
import ModalEditDepartment from 'components/NewDepartment/EditDepartment'
import { Context } from 'contexts/context'
import { handleCompaniesShow } from 'services/api/companies'
import ModalNewCompany from 'components/NewCompany'
import ModalEditCompany from 'components/NewCompany/EditCompany'

const Component = props => {
  const breadcrumb = [{ path: '/usuarios', label: 'Index' }]
  const [data, setData] = useState([])
  const [companies, setCompanies] = useState([])
  const [newDepartment, setNewDepartment] = useState(false);
  const [editDepartment, setEditDepartment] = useState(false)
  const [newCompany, setNewCompany] = useState(false);
  const [editCompany, setEditCompany] = useState(false)
  const { user: { firm_id } } = useContext(Context)

  async function fetchData() {
    setCompanies(await handleCompaniesShow())
    setData(await handleDepartmentShow());
    setCompanies(await handleCompaniesShow())
  }
  
  useEffect(() => {
    fetchData();
  }, [newDepartment, editDepartment, newCompany, editCompany]);

  function departmentModal() {
    setNewDepartment(true);
  }

  function departmentModalEdit() {
    setEditDepartment(true);
  }

  function companyModal() {
    setNewCompany(true);
  }

  function companyModalEdit() {
    setEditCompany(true);
  }

  const closeModalAndReload = () => {
    setNewDepartment(false);
    setEditDepartment(false);
    //reloadPage()
    fetchData()
  }

  const closeModalCompanyAndReload = () => {
    setNewCompany(false);
    setEditCompany(false);
    //reloadPage()
    fetchData()
  }

  // function reloadPage() {
  //   window.location.reload()
  // }

  async function submitModalNew (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  async function submitModalEdit (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  async function submitModalNewCompany (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalCompanyAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  async function submitModalEditCompany (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalCompanyAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  const handleCheckboxClick = () => {
    const newValue = !props.formik.values.blocked_user ? 1 : 0;
    props.formik.setFieldValue('blocked_user', newValue);
  };

  return (
    <>
      <TitleBar label="Usuários" currentPage="" />
      <Content>
        <Card>
          <CardTitle title="Cadastrar"></CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={props.formik.handleSubmit}>
                <Messages formMessages={props.messages.messages} alert={props.messages.alert} />
                <Input name="name" label="Nome" formik={props.formik} />
                <Input name="email" label="Email" formik={props.formik} />
                <SelectDepartment
                  name="roles"
                  label="Permissão"
                  formik={props.formik}
                  options={[
                    {value: 'admin', label: 'Administrador'},
                    {value: 'user', label: 'Usuário'}
                  ]}
                />
                
                <SelectDepartment
                  name="department_id"
                  label="Departamento"
                  value=''
                  formik={props.formik}
                  moreItems={[
                    <div key={0}>
                      <IconButton 
                        Icon={RiAddLine} 
                        color='#082127' 
                        text='Criar'
                        onClick={departmentModal}
                        style={{marginLeft: "10px"}}
                      />
                    </div>,
                    <div key={1}>
                    <IconButton 
                      Icon={RiPencilLine} 
                      color='#082127' 
                      text='Editar'
                      onClick={departmentModalEdit}
                      style={{marginLeft: "10px"}}
                    />
                  </div>
                  ]}
                  options={[
                    {
                      value: '',
                      label: 'Sem departamento'
                    },
                     ...data.map((d) => ({ value: d.department_id, label: d.description }))
                  ]}
                    />
                {
                  // firm_id === 11
                (firm_id === 11 || firm_id === 19 || firm_id === 20)
                 && (
                  <SelectDepartment
                    name="company_id"
                    label="Empresa"
                    value=''
                    defaultValue={{
                      value: props.formik.initialValues.company_id.value,
                      label: props.formik.initialValues.company_id.label
                    }}
                    formik={props.formik}
                    moreItems={[
                      <div key={0}>
                        <IconButton 
                          Icon={RiAddLine} 
                          color='#082127' 
                          text='Criar'
                          onClick={companyModal}
                          style={{marginLeft: "10px"}}
                        />
                      </div>,
                      <div key={1}>
                      <IconButton 
                        Icon={RiPencilLine} 
                        color='#082127' 
                        text='Editar'
                        onClick={companyModalEdit}
                        style={{marginLeft: "10px"}}
                      />
                    </div>
                    ]}
                    options={[
                      {
                        value: '',
                        label: 'Sem empresa'
                      },
                       ...companies.map((d) => ({ value: d.company_id, label: d.description }))
                    ]}
                  />
                  )}
                <Checkbox
                  name='blocked_user'
                  label='Bloquear Usuário'
                  formik={props.formik}
                  onClick={handleCheckboxClick}
                  // checked={}
                />
                <BtnBox>
                  <BtnBlue type="submit" disabled={props.isSubmitting}>
                    {props.isSubmitting? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
        <ModalNewDepartment
          formMessages={props.messages.messages}
          isOpen={newDepartment} 
          onClose={() => {
            setNewDepartment(false)
            props.formikModal.resetForm()
          }} 
          onSubmit={
            // async (values) => { await submitModalNew(props.formikModal, values)}
            (values) => submitModalNew(props.formikModal, values)
          }
          formikModal={props.formikModal}
          />

        <ModalEditDepartment
          formMessages={props.messages.messages}
          isOpen={editDepartment} 
          onClose={() => {
            setEditDepartment(false)
            props.formikModalEdit.resetForm()
          }} 
          onSubmit={
            async (values) => { await submitModalEdit(props.formikModalEdit, values)
          }}
          formikModalEdit={props.formikModalEdit}
          data={data}
          />

        <ModalNewCompany 
          formMessages={props.messages.messages}
          isOpen={newCompany} 
          onClose={() => {
            setNewCompany(false)
            props.formikModalCompany.resetForm()
            //reloadPage()
            fetchData()
          }} 
          onSubmit={
            async (values) => { 
              await submitModalNewCompany(props.formikModalCompany, values)
          }}
          formikModalCompany={props.formikModalCompany}
        />
        <ModalEditCompany
          formMessages={props.messages.messages}
          isOpen={editCompany} 
          onClose={() => {
            setEditCompany(false)
            props.formikModalCompanyEdit.resetForm()
            // reloadPage()
            fetchData()
          }} 
          onSubmit={
            async (values) => {
              await submitModalEditCompany(props.formikModalCompanyEdit, values)
          }}

          formikModalCompanyEdit={props.formikModalCompanyEdit}
          companies={companies}
          />
      </Content>
    </>
  )
}

export default Component
import styled from 'styled-components'

export const Card = styled.div`
    background-color: var(--color-background-card);
    margin: 0 15px 15px 15px;
    border-radius: 20px;
    color: var(--color-font);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2), -2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    // max-width: 100%;
    width: 95vw;
    max-width: 95vw;
    
    @media (max-width: 800px) {
        max-width: 100vw;
    }
`
import styled from 'styled-components'

export const InputsContainer = styled.div`
    padding: 0 30px 15px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 100%;

    .pickers {
        display: flex;
    }

    .pickers :first-child {
        margin-right: 8px;
    }

    .actions {
        display: flex;
    }

    .actions :first-child {
        margin-right: 8px;
    }

    @media (max-width: 950px) {
        flex-direction: column;
        align-items: flex-start;

        padding-bottom: 32px;

        .pickers {
            flex-direction: column;
            width: 100%;
        }

        .actions {
            width: 100%;
            justify-content: space-between;
        }
    }
`

export const AnalyticsContainer = styled.div`
    width: 100%;
    max-width: 100%;
    height: 300px;
    padding: 0 15px 15px 15px;
    margin-bottom: 16px;

    .recharts-wrapper, .recharts-surface {
        width: 100% !important;
    }

    .recharts-default-tooltip { 
        overflow-y: auto; 

        .recharts-tooltip-item-list { 
            height: 100%;
        } 
    }
`

export const CustomTooltipContainer = styled.div`
    margin: 0;
    line-height: 24px;
    border: 1px solid #f5f5f5;
    background-color: hsla(0,0%,100%,.8);
    padding: 10px;
    max-width: 400px;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: 600px) {
        max-width: 200px;
    } 
`

export const CustomTooltipLabel = styled.p`
    margin: 0;
    margin-bottom: 10px;
    color: #666;
    font-weight: 700
`

export const CustomTooltipIntro = styled.p`
    border-top: 1px solid #f5f5f5;
    margin: 0;
    margin-bottom: 10px;
`

export const CustomTooltipDesc = styled.p`
    margin: 0;
    color: #999
`

export const CustomSearchInputContainer = styled.div`
    padding: 15px 15px 0 15px;
`
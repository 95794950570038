import api from './api'
import { setErrors } from 'utils'

export const handleCards = async () => (await api.post(`/report-cards/list-by-firm`)).data

export const handleCardShow = async id => (await api.get(`/report-cards/${id}`)).data

export const handleReportCardsByFirm = async () =>
  (await api.post(`/report-cards/list-by-firm`)).data

export const handleReportCardsStore = async (history, data, setMessages) => {
  const values = {
    "group_id": data.group_id,
    "report_id": data.report_id,
    "dataset_id": data.dataset_id,
    "group_dataset_id": data.group_dataset_id,
    "name": data.name,
    "company_id": data.company_id,
    "department_id": data.department_id
  }

  try {
    const response = await api.post(`/report-cards/store`, values)
    return history.push(`/cards/${response.data.id}/upload`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleCardReport = async (history, data, cardId, setMessages) => {

  let companyInfo = data.company_id
  if(!data.company_id) companyInfo = null
  else companyInfo = data.company_id.value

  let departmentInfo = data.department_id
  if(!data.department_id) departmentInfo = null
  else departmentInfo = data.department_id.value

  const values = {
    "group_id": data.group_id,
    "report_id": data.report_id,
    "dataset_id": data.dataset_id,
    "group_dataset_id": data.group_dataset_id,
    "name": data.name,
    "company_id": companyInfo,
    "department_id": departmentInfo
  }

  try {
    await api.put(`/report-cards/${cardId}/update`, values)
    return history.push(`/cards`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleCardDelete = async (id, history) => {
  await api.delete(`/report-cards/${id}`)
  return history.push(`/cards`)
}

export const handleCardUpdateFile = async (data, id) => {
  const values = new FormData()
  values.append('file', data) 
  try {
    await api.put(`/report-cards/${id}/upload`, values)
    return
  } catch (error) {
    return error
  }
}
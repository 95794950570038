import styled from 'styled-components'

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const ReactDatePicker = styled(DatePicker)`
    border: 1px solid var(--color-grey);
    color: var(--color-font);
    font-size: 16px;
    padding: 10px 20px;
    margin-top: 8px;
    border-radius: 20px;
    width: 100%;
    background-color: var(--color-background-input);

    &:focus {
        outline: none;
    }
`

import { Link, useHistory } from 'react-router-dom'
import { Breadcrumb, TitleBar, Background } from './styled'
import { FaArrowLeft } from 'react-icons/fa'

const Component = props => {
    const history = useHistory();

    const handleClick = () => {
        history.goBack();
    };
    return (
        <Background>
            <TitleBar>
                <div>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <span style={{paddingRight: "10px"}}>
                        <FaArrowLeft
                            style={{
                                cursor: 'pointer',
                                transition: 'color 0.3s'
                            }}
                            color="var(--color-font)"
                            size={22}
                            onClick={handleClick}
                            onMouseOver={(e) => { e.target.style.color = 'var(--color-grey)'; }} // Muda a cor ao passar o mouse
                            onMouseOut={(e) => { e.target.style.color = 'var(--color-font)'; }} // Volta a cor ao retirar o mouse
                        />
                        </span>
                    
                    <h1>{props.label}</h1>
                    </div>
                    <Breadcrumb>
                        {
                            props.breadcrumb && props.breadcrumb.map(
                                (item, index) => <li key={index}><Link to={item.path}>{item.label}</Link>/</li>
                            )
                        }
                        <li>{props.currentPage}</li>
                    </Breadcrumb>
                </div>
            </TitleBar>
        </Background>
    )
}

export default Component
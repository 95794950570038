import styled from 'styled-components'

// export const StyledList = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// export const ListItem = styled.div`
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   width: 100%;
//   background-color: ${({ isHeader }) => (isHeader ? '#F2FAFD' : 'var(--color-white)')};
//   // background-color: var(--color-white);
//   border-bottom: 1px solid #ccc;
//   border-top: 1px solid #ccc;
//   // padding: 10px;
// `;

export const CheckboxWrapper = styled.div`
  // width: 25%;
  display: flex;
  justify-content: center;
`;

// export const CustomSelect = styled.select`
//   width: 100%;
//   padding: 5px;
//   border: 2px solid #ccc;
//   border-radius: 5px;
//   font-family: Arial, Helvetica, sans-serif;
//   font-size: 16px;
// `;

export const TimeSelectWrapper = styled.div`
  // width: 25%;
  text-align: center;
  padding: 5px;
`;

// export const Titles = styled.div`
//   font-weight: bold;
//   text-align: center;
//   width: 25%;
//   padding: 10px;
// `

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  & tr:nth-child(even) {
    background-color: var(--color-background-input);
}
`;

export const TableHeader = styled.th`
  padding: 10px;
  background-color: #F2FAFD;
  border-bottom: 3px solid #D3CEDE;
  border-top: 2px solid #D3CEDE;
  text-align: center;
`;

export const TableRow = styled.tr`
  background-color: ${({ isHeader }) => (isHeader ? '#F2FAFD' : 'var(--color-white)')};
  border-bottom: 2px solid #D3CEDE;
`;

export const TableCell = styled.td`
  padding: 6px;
  text-align: center;
`;
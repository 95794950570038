import styled from 'styled-components';
import Tippy from '@tippyjs/react';

export const InputCadastrar = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // width: 30vw;
//   @media(max-width: 500px) {
//     flex-direction: column;
// }
`;

export const DepartmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #D3CEDE;
  section {
    font-size: 18px;
    font-weight: bold;
    }
  button {
    margin-right: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: var(--color-orange);
  }
`

export const SelectDepartmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`

export const DepartmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: start;
  flex-wrap: wrap;
  max-width: 100%;

  @media (max-width: 575px) {
    justify-content: center;
  }
  `

export const IconCards = styled.img`
  margin: 10px;
  width: 34px;
  height: 34px;
  padding: 5px;
  opacity: ${props => props.op ? 1 : 0.3};
  
  &:hover {
    cursor: pointer;
    background-color: lightgrey;
    opacity: 1;
    position: relative;
  }
`

export const GreyTippy = styled(Tippy)`
  background: var(--color-orange);
  font-size: 13px;
  color: var(--color-background-report-card);
`;

export const VerticalLine = styled.hr`
  width: 2px;
  height: 30px;
  margin: 0px 20px;
  border: none;
  background-color: var(--color-orange);
`;

export const CotainerReportCardCarousel = styled.div`
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;

  display: flex;
  flex-direction: row;
  // justify-content: start;
  flex-wrap: nowrap;
  width: 100%;

  & > * {
    flex: 1; // Isso garante que todos os filhos tenham o mesmo tamanho
  }
`

export const InputIconsDiv = styled.div`
  padding: 15px;

  @media (max-width: 650px) {
        & {
            padding: 5px 15px
            ;
        }
    }
`
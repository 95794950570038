import ReactPaginate from 'react-paginate';

import { Container, PaginationContainer } from './styled'


const Component = ({ pageCount, handlePageClick }) => {
  return (
    <Container>
      <PaginationContainer>
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        /> 
      </PaginationContainer>
    </Container>
  )
}

export default Component

import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBox = styled.div`
  width: 40%;
  background: #fff;
  padding: 30px;
  border-radius: 8px;

  @media (max-width: 700px) {
    width: 70%;
  }
`;

export const ButtonDepartment = styled.button`
    padding: 10px;
    align-items: center;
    border: none;
    border-radius: 30px;
    color: var(--color-white);
    background-color: #082127;
    display:flex;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
    transition: all 0.3s;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
    &:focus {
        outline: 0;
    }
`

export const ButtonsBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
`
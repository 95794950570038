import { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { handleFirm, handleFirmByDomain } from 'services/api/firms'
import { handleReportCustom } from 'services/api/report_task'

export const Context = createContext()

const ContextProvider = ({ children }) => {
    const [toggle, setToggle] = useState(true)
    const [user, setUser] = useState(null)
    const [report, setReport] = useState()
    const [portrait, setPortrait] = useState(true)
    const [topBarShow, setTopBarShow] = useState(true)
    const [painelTV, setPainelTV] = useState(true)

    const [firm, setFirm] = useState(null)
    const [allFirms, setAllFirms] = useState(null)
    const [reportCustom, setReportCustom] = useState({})
    const [favorites, setFavorites] = useState({});
    const [showNotifications, setShowNotifications] = useState(false);
    const location = useLocation()

    useEffect(async () => {
      const hostname = window.location.hostname

      const currentFirm = await handleFirmByDomain(hostname)
      // const currentFirm = firms.find(({ domainPrefix }) => hostname.includes(domainPrefix))
      if(!currentFirm) return

      setAllFirms(currentFirm)
      if (user) {
        const chooseFirm = await handleFirm(user.firm_id)
        setFirm(chooseFirm)
      } else {
        setFirm(currentFirm[0])
      }
    }, [user])

    useEffect(async () => {
      const reportTask = await handleReportCustom()
      setReportCustom(reportTask)
    },[])

    const isFavorite = (reportCardId) => {
      return favorites[reportCardId] !== undefined ? favorites[reportCardId] : false;
    };

    return (
      <Context.Provider value={{ 
      toggle, setToggle, 
      user, setUser, 
      report, setReport, 
      portrait, setPortrait,
      topBarShow, setTopBarShow,
      painelTV, setPainelTV,
      firm, setFirm,
      allFirms, 
      reportCustom, setReportCustom,
      favorites, setFavorites, isFavorite,
      showNotifications, setShowNotifications
      }}>
        {children}
      </Context.Provider>
    )
}

export default ContextProvider
import styled from 'styled-components'

import {
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem
} from "@coreui/react"

export const Container = styled(CDropdown)``

export const Button = styled(CDropdownToggle)`
    background-color: var(--color-orange) !important;
    border-radius: 30px;
    border: none;
    transition: all 0.3s;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    font-weight: bold;

    &:hover {
        opacity: 0.5;
    }

    &:focus {
        outline: 0;
    }

    @media (max-width: 400px) {
        font-size: 15px !important;
    } 
`

export const Menu = styled(CDropdownMenu)`
    margin-top: 8px;
`

export const MenuItem = styled(CDropdownItem)``
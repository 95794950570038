import styled from 'styled-components'

export const Login = styled.div`
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  & h1 {
    font-size: 32px;
    padding: 10px 0;
    color: var(--color-font);
    text-align: start;
  }

  @media (max-width: 450px) {
    margin-bottom: 15px;
  }

  @media (max-width: 350px) {
    margin-bottom: 5px;
    padding: 0 .5rem;
  }

`
export const SubTitle = styled.p`
  color: var(--color-font);
  font-weight: bold;
  margin-bottom: 25px;

  @media (max-width: 450px) {
    margin-bottom: 15px;
  }
`
export const InputBox = styled.div`
  padding: 10px 0;
  max-width: 100%;

  @media (max-width: 450px) {
    padding: 5px 4px;
  }
`
export const BtnBox = styled.div`
  padding: 2rem 0;
  width: 100%;

  & button {
    width: 100%;
  }

  @media (max-width: 450px) {
    padding: .8rem;
  }
`
export const ForgotPassword = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  & a {
    text-decoration: none;
    color: var(--color-blue);
    font-weight: bold;
  }
`
export const Form = styled.form`
  width: 100%;

`

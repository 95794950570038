import SelectDepartment from "components/Form/SelectDepartment";
import { ButtonDepartment, ButtonsBox, ModalBox, ModalOverlay } from "./styled";
import Input from 'components/Form/LabelInput'

export const ModalEditCompany = props => {
    return (
      <>
        {props.isOpen && (
          <ModalOverlay>
            <ModalBox>
            <SelectDepartment
              name="company_id"
              label="Empresa"
              value=''
              formik={props.formikModalCompanyEdit}
              options={[
               ...props.companies.map((d) => ({ value: d.company_id, label: d.description }))
              ]}
            />
              <Input
              value=''
              name="company" 
              label="Novo nome da empresa"
              formik={props.formikModalCompanyEdit}
              />
              <ButtonsBox>
                <ButtonDepartment 
                    onClick={props.onSubmit}
                    type="submit">
                        Salvar edição
                </ButtonDepartment>
                <ButtonDepartment 
                    onClick={props.onClose}>Fechar</ButtonDepartment>
              </ButtonsBox>
            </ModalBox>
          </ModalOverlay>
        )}
      </>
    );
  };
  
  export default ModalEditCompany;
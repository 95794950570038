import styled from 'styled-components';

export const StyledSelect = styled.select`
    display: block;
    margin: 0 auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    &:focus {
        outline: none;
        border-color: var(--color-orange);
    }
`;

export const Option = styled.option`
    text-align: center;

    &:checked {
        background-color: var(--color-grey);
    }
`;

export const SelectContainer = styled.div`
    // width: 100vw;
`;
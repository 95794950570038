import React from 'react';
import { InputField, SearchIcon, StyledInput } from './styled';

const InputWithSearchIcon = ({ value, onChange, placeholder }) => {
    return (
      <StyledInput>
        <InputField
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <SearchIcon />
      </StyledInput>
    );
  };
  
  export default InputWithSearchIcon;
import styled from 'styled-components'

export const Container = styled.div`
    max-width: 100%;
    overflow-x: auto;
    padding: 0 7px 7px 7px;
`

export const Table = styled.table`
    width: 100%;
    padding: 0 15px 15px;
    & th {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid var(--color-grey);
    }
    & tr:nth-child(even) {
        background-color: var(--color-background-input);
    }
    & td {
        padding: 8px 12px;
    }
`

export const TotalUsers = styled.div`
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    // background-color: red;
`

import styled from 'styled-components';

export const NotificationMenu = styled.div`
    position: fixed;
    padding: 20px 30px;
    top: 59px;
    font-size: 20px;
    right: 0;
    width: 30%; /* Ajuste conforme necessário */
    height: 100%;
    background-color: white; /* Cor de fundo */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Para garantir que fique acima de outros elementos */
    overflow-y: auto; /* Para permitir rolagem se necessário */

    @media (max-width: 950px) {
        width: 50%;
    }
    
    @media (max-width: 500px) {
        width: 65%;
    }
`;

export const NotificationTitle = styled.div`
    display: flex;
    flex-direction: line;
    justify-content: space-between;
`

export const Notification = styled.div`
    margin-top: 20px;
    padding: 15px;
    font-size: 16px;
    border-radius: 20px;
    background-color: rgba(211, 211, 211, 0.3);
`

import { Option, SelectContainer, StyledSelect } from "./styled";

const TimeSelect = ({ time, formik, name,defaultValue, disabled }) => {

  return (
    <SelectContainer>
      <StyledSelect
        name={name}
        value={formik.values[name] || defaultValue}
        onChange={(e) => formik.setFieldValue(name, e.target.value)}
        disabled={disabled}
        >
        <Option value="">-</Option>
        {time.map(t => <Option value={t} key={t}>{t}:00</Option>)}
      </StyledSelect>
    </SelectContainer>
  )
}
  
  export default TimeSelect;
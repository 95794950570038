import styled from 'styled-components'

export const IconButtonContainer = styled.button`
    background-color: ${props => props.color};
    color: #fff;
    border: 1px solid ${props => props.color};
    padding: 2px 4px;
    border-radius: 10px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;

    & span {
        font-size: 11px;
    }

    &:hover {
        opacity: 0.5;
        transition: .5s;
    }
`

/* 


    &:focus {
        outline: 0;
    } */
import { useEffect, useState } from 'react'

import { handleUsers } from 'services/api/users'

import Component from './Component'

const Page = () => {
    const [data, setData] = useState([])
    useEffect(() => {
        (async () => {
            try {
                setData(await handleUsers())
            } catch(error) {}
        })()
    }, [])

    return <Component data={data} />
}

export default Page
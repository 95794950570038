import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getUser } from 'services/auth'
import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'
import { handleReportCardsStore } from 'services/api/reportCards'
import Validation from './validation'
import { handlePowerBiGroups, handlePowerBiReports } from 'services/api/powerbi'
import SelectLabel from 'components/Form/LabelSelect'
import Select from 'components/Form/Select'
import { handleCompaniesShow } from 'services/api/companies'
import { Context } from 'contexts/context'
import SelectDepartment from 'components/Form/SelectDepartment'
import { handleDepartmentShow, handleStoreDepartment, handleUpdateDepartment } from 'services/api/department'
import IconButton from 'components/Button/IconButton'
import ModalNewDepartment from 'components/NewDepartment'
import ModalEditDepartment from 'components/NewDepartment/EditDepartment'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'

const Page = () => {

  const [user, setUser] = useState({})
  const [groups, setGroups] = useState([])
  const [reports, setReports] = useState([])
  const [companies, setCompanies] = useState([])
  const [departments, setDepartments] = useState([])
  const [newDepartment, setNewDepartment] = useState(false)
  const [editDepartment, setEditDepartment] = useState(false)
  const { user: { firm_id } } = useContext(Context)

  async function fetchData() {
  setCompanies(await handleCompaniesShow())
  setDepartments(await handleDepartmentShow());
  }

  useEffect(async() => {
    setUser(getUser().data)
    await fetchData()
  }, [newDepartment, editDepartment])

  useEffect(() => {
    (async () => {
      const fetchedGroups = await handlePowerBiGroups();
      const sortedGroups = fetchedGroups.sort((a, b) => a.name.localeCompare(b.name));
      setGroups(sortedGroups);
    })();
  }, []);


  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const history = useHistory()
  const formik = useFormik({
    initialValues: { name: '', group_id: '', report_id: '', dataset_id: '', group_dataset_id:'', company_id: '', department_id: '' },
    validationSchema: Yup.object(Validation(Yup)),
    
    onSubmit: async () => {
      // if(formik.values.company_id !== undefined || null || '' ) formik.setFieldValue('company_id', formik.values.company_id.value)
        // formik.values.company_id = formik.values.company_id.value
      // formik.values.department_id = formik.values.department_id.value
      const companyIdValue = formik.values.company_id?.value || '';
      const departmentIdValue = formik.values.department_id?.value || '';
      formik.setFieldValue('group_id', formik.values.group_id.value)
      formik.setFieldValue('report_id', formik.values.report_id.value)
      handleReportCardsStore(history, { ...formik.values, department_id: departmentIdValue, company_id: companyIdValue} , setMessages)
    }
  })

  useEffect(() => {
    (async () => {
      if (formik.values.group_id) {
      const fetchedReports = await handlePowerBiReports(formik.values.group_id);
      const sortedReports = fetchedReports.sort((a, b) => a.name.localeCompare(b.name));
      setReports(sortedReports);
      formik.setFieldValue('name', "")
      formik.setFieldValue('report_id', "")
      }
    })()
  }, [formik.values.group_id])

  // useEffect(() => {
  //   (async () => {
  //     if (formik.values.group_id) {
  //       setReports(await handlePowerBiReports(formik.values.group_id))
  //     }
  //   })()
  // }, [formik.values.group_id])

  useEffect(() => {
    if (formik.values.report_id) {
      formik.setFieldValue('name', (reports.find(element => element.id === formik.values.report_id)).name)
      formik.setFieldValue('dataset_id', (reports.find(element => element.id === formik.values.report_id)).datasetId)
      formik.setFieldValue('group_dataset_id', (reports.find(element => element.id === formik.values.report_id)).datasetWorkspaceId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.report_id])

  const handleResetForm = (formik) => {
    formik.resetForm();
  };

  const formikModal = useFormik({
    initialValues: { department: '' },
    onSubmit: async () => {
        handleStoreDepartment(formikModal.values, setMessages)
        handleResetForm(formikModal)
    }
    })

  const formikModalEdit = useFormik({
    initialValues: { department_id: '' },
    onSubmit: async () => {
        formikModalEdit.values.department_id = formikModalEdit.values.department_id.value
        handleUpdateDepartment(formikModalEdit.values, setMessages)
        handleResetForm(formikModalEdit)
    }
    })

    const closeModalAndReload = () => {
      setNewDepartment(false);
      setEditDepartment(false);
      //reloadPage()
      fetchData()
    }

  const breadcrumb = [{ path: '/cards', label: 'Index' }]

  function departmentModal() {
    setNewDepartment(true);
  }

  function departmentModalEdit() {
    setEditDepartment(true);
  }

  async function submitModalNew (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  async function submitModalEdit (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  return (
    <>
      <TitleBar label="Cards" currentPage="" />
      <Content>
        <Card>
          <CardTitle title="Cadastrar"></CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages formMessages={messages.messages} alert={messages.alert} />
                <Select name="group_id" label="Workspaces" formik={formik}
                  options={groups.map(group => ({ value: group.id, label: group.name }))}
                  />
                  {/* <option value="">Selecione...</option> */}
                  {/* {groups.map(group => <option key={group.id} value={group.id}>{group.name}</option>)} */}


                <Select 
                defaultValue={{value: "", label: "Selecione..."}}
                name="report_id" label="Relatórios" formik={formik}
                options={
                  reports.map(report => ({ value: report.id, label: report.name }))
                }
                  />
                  {/* <option value="">Selecione...</option> */}
                  {/* {reports.map(report => <option key={report.id} value={report.id}>{report.name}</option>)} */}
                {/* </Select> */}
                <Input name="name" label="Nome" formik={formik} />
                
                <SelectDepartment
                  name="department_id"
                  label="Departamento"
                  value=''
                  formik={formik}
                  moreItems={[
                    <div key={0}>
                      <IconButton 
                        Icon={RiAddLine} 
                        color='#082127' 
                        text='Criar'
                        onClick={departmentModal}
                        style={{marginLeft: "10px"}}
                      />
                    </div>,
                    <div key={1}>
                    <IconButton 
                      Icon={RiPencilLine} 
                      color='#082127' 
                      text='Editar'
                      onClick={departmentModalEdit}
                      style={{marginLeft: "10px"}}
                    />
                  </div>
                  ]}
                  options={[
                    {
                      value: '',
                      label: 'Sem departamento'
                    },
                     ...departments.map((d) => ({ value: d.department_id, label: d.description }))
                  ]}
                    />

                {
                  // firm_id === 11
                (firm_id === 11 || firm_id === 19 || firm_id === 20) 
                && ( 
                  <SelectDepartment
                    name="company_id"
                    label="Empresa"
                    value=''
                    formik={formik}
                    options={[
                      {
                        value: '',
                        label: 'Sem empresa'
                      },
                       ...companies.map((d) => ({ value: d.company_id, label: d.description }))
                    ]}
                    />)}

                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
        <ModalNewDepartment
          formMessages={messages.messages}
          isOpen={newDepartment} 
          onClose={() => {
            setNewDepartment(false)
            formikModal.resetForm()
          }} 
          onSubmit={
            // async (values) => { await submitModalNew(props.formikModal, values)}
            (values) => submitModalNew(formikModal, values)
          }
          formikModal={formikModal}
          />

        <ModalEditDepartment
          formMessages={messages.messages}
          isOpen={editDepartment} 
          onClose={() => {
            setEditDepartment(false)
            formikModalEdit.resetForm()
          }} 
          onSubmit={
            async (values) => { await submitModalEdit(formikModalEdit, values)
          }}
          formikModalEdit={formikModalEdit}
          data={departments}
          />

      </Content>
    </>
  )
}

export default Page